<script setup lang="ts">
const { t } = useI18n()
</script>

<template>
  <!-- Pre-footer -->
  <div class="mx-auto max-w-7xl md:n-border-200 md:border-t px4 py6 lg:px8 lg:py12 sm:px6">
    <div class="pt4 xl:mt0">
      <div class="mx-auto max-w-7xl overflow-hidden border-t n-border-200 px6 pt6 lg:px8">
        <nav class="columns-2 -mb6 sm:flex sm:flex-wrap sm:justify-around sm:space-x-6" aria-label="Footer">
          <!--            <template v-if="navigation?.legal?.length"> -->
          <!--              <div v-for="item in navigation?.legal" :key="item._path" class="pb-6"> -->
          <!--                <NuxtLinkLocale :to="item._path" class="text-sm text-slate-500 leading-6 hover:text-slate-900"> -->
          <!--                  {{ item.title }} -->
          <!--                </NuxtLinkLocale> -->
          <!--              </div> -->
          <!--            </template> -->
          <div v-for="i in 10" :key="i" class="pb-6">
            <NSkeleton class="w-24" :style="{ width: `${100 + (i * i + i) % 7 * 20}px` }" pulse />
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>
