<script setup lang="ts">
import { useRouteQuery } from '@vueuse/router'

const { t } = useI18n()
const localePath = useLocalePath()
const { ecommerce } = useAnalytics()
const router = useRouter()
const route = useRoute()
const drawers = useDrawers()

const searchQuery = useRouteQuery('q', '')
const searchQueryInput = ref(searchQuery.value)

const isSearchPage = computed(() => ((route?.name as string) ?? '').includes('search'))
const _isProductPage = computed(() => ((route?.name as string) ?? '').includes('products-id-slug'))
const isCheckoutPage = computed(() => ((route?.name as string) ?? '').includes('checkout'))

async function handleCartClick() {
  drawers.value.cart.open = true
  ecommerce.viewCart()
}

function handleNavigationClick() {
  drawers.value.navigation.open = true
}

function submitSearch(event: Event) {
  event.preventDefault()
  router.push({
    path: localePath('/search'),
    query: { q: searchQueryInput.value },
  })
}

watch(isSearchPage, (isSearchPage) => {
  if (!isSearchPage)
    searchQueryInput.value = ''
})
</script>

<template>
  <header data-testid="header" class="relative wfull bg-white">
    <HeaderMetaBar class="hidden sm:block" />
    <HeaderTopRowContainer />
    <HeaderNavigation class="hidden sm:block" />
    <UspBanner />
  </header>

  <slot />

  <NavDrawer />
  <CartDrawer />

  <div class="flex-1" />

  <!-- Footer -->
  <LayoutFooter />
</template>
